const ETHIcon = require('@/assets/cryptoicons/ETHicon.webp');
const BETSIcon = require('@/assets/cryptoicons/BETSIcon.webp');
const LINKicon = require('@/assets/cryptoicons/LINKicon.webp');
const BNBicon = require('@/assets/cryptoicons/BNBicon2.webp');
const UNKNOWNicon = require('@/assets/cryptoicons/UNKNOWNicon.webp');

const BASEicon = require('@/assets/networkicons/BASEicon.webp');
const ARBITRUMicon = require('@/assets/networkicons/ARBITRUMicon.webp');
const POLYGONicon = require('@/assets/networkicons/POLYGONicon.webp');
const BSCicon = require('@/assets/networkicons/BSCicon.webp');

export default function getCryptoIcon({
  mainCurrency,
  network,
  token,
}: {
  mainCurrency: boolean;
  network?: string;
  token?: string;
}) {
  if (mainCurrency) {
    if (network === 'ETH' || network === 'Arbitrum' || network === 'Base' || network === 'Sepolia') return ETHIcon;
    if (network === 'BSC') return BNBicon;
    if (network === 'Polygon') return POLYGONicon;
  }

  if (token === '0x779877A7B0D9E8603169DdbD7836e478b4624789') return LINKicon;
  if (token === '0x42069dE48741Db40aeF864F8764432bBccbD0B69') return BETSIcon;

  return UNKNOWNicon;
}

export function getNetworkIcon(network?: string) {
  if (network === 'Sepolia') return ETHIcon;
  if (network === 'Base') return BASEicon;
  if (network === 'Arbitrum') return ARBITRUMicon;
  if (network === 'Polygon') return POLYGONicon;
  if (network === 'BSC') return BSCicon;
  return UNKNOWNicon;
}

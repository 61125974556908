import getCryptoIcon, { getNetworkIcon } from '@/utils/getCryptoIcon';
import { CONTRACT_ADDRESSES } from './SmartContract/ContractConfig';

export interface ICurrencyProps {
  icon?: string;
  symbol: string;
  slug: string;
  name: string;
  address?: string;
}

export interface ICurrency {
  [symbol: string]: ICurrencyProps;
}

export interface INetwork {
  contractAddress: string | undefined;
  name: string;
  icon: string;
  chainId: string;
  mainCurrency: string;
  currenciesList: ICurrency;
}

const NETWORKS: { [networkName: string]: INetwork } = {
  Base: {
    contractAddress: CONTRACT_ADDRESSES['Base'],
    name: 'Base',
    icon: getNetworkIcon('Base'),
    chainId: '0x2105',
    mainCurrency: 'ETH',
    currenciesList: {
      ETH: {
        address: undefined,
        icon: getCryptoIcon({ mainCurrency: true, network: 'Base' }),
        symbol: 'ETH',
        slug: 'ethereum',
        name: 'Ethereum',
      },
      BETS: {
        icon: getCryptoIcon({ mainCurrency: false, token: '0x42069dE48741Db40aeF864F8764432bBccbD0B69' }),
        symbol: 'BETS',
        name: 'All street bets',
        slug: 'all-street-bets',
        address: '0x42069dE48741Db40aeF864F8764432bBccbD0B69',
      },
    },
  },

  Arbitrum: {
    contractAddress: CONTRACT_ADDRESSES['Arbitrum'],
    name: 'Arbitrum',
    icon: getNetworkIcon('Arbitrum'),
    chainId: '0xa4b1',
    mainCurrency: 'ETH',
    currenciesList: {
      ETH: {
        address: undefined,
        icon: getCryptoIcon({ mainCurrency: true, network: 'Arbitrum' }),
        symbol: 'ETH',
        slug: 'ethereum',
        name: 'Ethereum',
      },
    },
  },

  Polygon: {
    contractAddress: CONTRACT_ADDRESSES['Polygon'],
    name: 'Polygon',
    icon: getNetworkIcon('Polygon'),
    chainId: '0x89',
    mainCurrency: 'POL',
    currenciesList: {
      POL: {
        address: undefined,
        icon: getCryptoIcon({ mainCurrency: true, network: 'Polygon' }),
        symbol: 'POL',
        slug: 'pol',
        name: 'POL',
      },
    },
  },

  BSC: {
    contractAddress: CONTRACT_ADDRESSES['BSC'],
    name: 'Binance Smart Chain',
    icon: getNetworkIcon('BSC'),
    chainId: '0x89',
    mainCurrency: 'BNB',
    currenciesList: {
      BNB: {
        address: undefined,
        icon: getCryptoIcon({ mainCurrency: true, network: 'BSC' }),
        symbol: 'BNB',
        slug: 'bnb',
        name: 'BNB',
      },
    },
  },

  Sepolia: {
    contractAddress: CONTRACT_ADDRESSES['Sepolia'],
    name: 'Sepolia',
    icon: getNetworkIcon('Sepolia'),
    chainId: '0xaa36a7',
    mainCurrency: 'ETH',
    currenciesList: {
      ETH: {
        address: undefined,
        icon: getCryptoIcon({ mainCurrency: true, network: 'Sepolia' }),
        symbol: 'ETH',
        slug: 'ethereum',
        name: 'Ethereum',
      },
      LINK: {
        address: '0x779877A7B0D9E8603169DdbD7836e478b4624789',
        icon: getCryptoIcon({ mainCurrency: false, token: '0x779877A7B0D9E8603169DdbD7836e478b4624789' }),
        symbol: 'chainlink',
        slug: 'chainlink',
        name: 'Chainlink',
      },
    },
  },
};

export { NETWORKS };

import { useWalletContext } from '@/contexts/WalletContext';
import { SDKState } from '@metamask/sdk-react';
import React from 'react';

export default function useApplyMetamaskInfo() {
  const { feedWalletContext, activeNetwork } = useWalletContext();

  const applyMetamaskInfo = (sdkState: SDKState) => {
    const { sdk, account, chainId } = sdkState;

    const metamaskSDKProvider = sdk?.getProvider();
    if (metamaskSDKProvider) {
      feedWalletContext(metamaskSDKProvider, account, chainId);
    }
  };

  return { applyMetamaskInfo };
}

import React, { useState } from 'react';
import styled from 'styled-components';
import LockBox from './LockBox';
import SmartTable from '@/components/Generics/SmartTable';
import PrimaryButton from '@/components/Buttons/PrimaryButton';
import useReleaseFunds from '@/hooks/useReleaseFunds';
import getCryptoIcon from '@/utils/getCryptoIcon';
import { useScreenConfig } from '@/contexts/ScreenConfigContext';
import { toast } from 'react-toastify';
import { useWalletContext } from '@/contexts/WalletContext';
import { ITransactionFormatted } from '@/hooks/useGetWalletData';
import Balance from '@/components/Balance';

const LocksContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.5rem;
`;

const ConfigAndTable = styled.div`
  width: 100%;
  display: flex;
  gap: 2rem;

  @media (max-width: 720px) {
    flex-wrap: wrap;
  }
`;

const AmountContainer = styled.div`
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const ProfitSpan = styled.span<{ $variation?: number }>`
  font-weight: 600;
  color: ${({ $variation }) => {
    if ($variation === undefined) return 'var(--color-grey)';
    if ($variation > 0) return 'var(--color-green)';
    if ($variation < 0) return 'var(--color-red)';
    return 'var(--color-grey)';
  }} !important;
`;

export interface ITransactionToTable {
  ID: number;
  Amount: JSX.Element;
  'Unlock in': string | JSX.Element;
  Profit: string | JSX.Element;
  Claim: JSX.Element;
}

const messages = [
  'Not ready yet...',
  'The wait will be worth it...',
  'Almost there, hang tight!',
  'Patience is a virtue!',
  'Good things come to those who wait...',
];

function getRandomMessage() {
  const randomIndex = Math.floor(Math.random() * messages.length);
  return messages[randomIndex];
}

export default function Locks() {
  const [isClaiming, setIsClaiming] = useState(false);

  const [showAmountInUSD, setShowAmountInUSD] = useState(false);
  const [showDateInString, setShowDateInString] = useState(true);

  const { isMobile } = useScreenConfig();
  const { activeNetwork, walletData } = useWalletContext();

  const { releaseFunds } = useReleaseFunds();

  const formatTimeLeft = (timeInMilliseconds: number) => {
    const totalSeconds = Math.floor(timeInMilliseconds / 1000);
    const totalMinutes = Math.floor(totalSeconds / 60);
    const totalHours = Math.floor(totalMinutes / 60);
    const totalDays = Math.floor(totalHours / 24);
    const totalMonths = Math.floor(totalDays / 30); // Aproximando um mês como 30 dias
    const totalYears = Math.floor(totalMonths / 12);

    const years = totalYears;
    const months = totalMonths % 12;
    const days = totalDays % 30;
    const hours = totalHours % 24;
    const minutes = totalMinutes % 60;
    const seconds = totalSeconds % 60;

    if (years > 0) {
      return `${years} year${years > 1 ? 's' : ''} and ${months} month${months > 1 ? 's' : ''}`;
    } else if (months > 0) {
      return `${months} month${months > 1 ? 's' : ''} and ${days} day${days > 1 ? 's' : ''}`;
    } else if (days > 0) {
      return `${days} day${days > 1 ? 's' : ''} and ${hours} hour${hours > 1 ? 's' : ''}`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? 's' : ''} and ${minutes} minute${minutes > 1 ? 's' : ''}`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? 's' : ''}`;
    } else {
      return `${seconds} second${seconds > 1 ? 's' : ''}`;
    }
  };

  const formatToTable = (tx: ITransactionFormatted): ITransactionToTable => {
    const totalDuration = tx.unlockTimestamp - tx.startTimestamp;

    const elapsedTime = Date.now() - tx.startTimestamp;
    const percentage = totalDuration > 0 ? (elapsedTime / totalDuration) * 100 : 100;

    const timeLeftToUnlock = new Date(tx.unlockTimestamp).getTime() - Date.now();
    const timeLeftFormatted = formatTimeLeft(timeLeftToUnlock);
    const unlockIn = timeLeftToUnlock < 0 ? 'Unlocked' : timeLeftFormatted;

    const amountWithIcon = (
      <AmountContainer onClick={() => setShowAmountInUSD((prev) => !prev)}>
        <img
          src={getCryptoIcon({ mainCurrency: tx.isMainCurrency, token: tx.token, network: activeNetwork.name })}
          width={16}
        />
        {showAmountInUSD && tx.valueInUSDNow ? <span>{tx.valueInUSDNow.toFixed(2)}$</span> : <span>{tx.amount}</span>}
      </AmountContainer>
    );

    const variation = (function () {
      if (!tx.valueInUSDBefore || !tx.valueInUSDNow) return undefined;
      return (tx.valueInUSDBefore / tx.valueInUSDNow - 1) * 100 * -1;
    })();

    return {
      ID: tx.id,
      Amount: amountWithIcon,
      'Unlock in': (
        <span style={{ cursor: 'pointer' }} onClick={() => setShowDateInString((prev) => !prev)}>
          {showDateInString ? unlockIn : new Date(tx.unlockTimestamp).toLocaleString()}
        </span>
      ),

      Profit: variation ? (
        <ProfitSpan $variation={variation}>{`${variation > 0 ? '+' : ''}${variation.toFixed(2)}%`}</ProfitSpan>
      ) : (
        'No Info'
      ),
      Claim: (
        <PrimaryButton
          color={`linear-gradient(90deg, var(--color-accent) ${percentage}%, var(--color-secondary) ${percentage}%)`}
          label="CLAIM"
          attributes={{
            disabled: isClaiming,
            onClick: () => {
              if (percentage < 100) return toast.error(getRandomMessage());
              releaseFunds({ lockId: tx.id }, setIsClaiming);
            },
          }}
        />
      ),
    };
  };

  const transactionsToTable = walletData?.locks.transactions.map((tx) => formatToTable(tx));

  return (
    <LocksContainer>
      <ConfigAndTable>
        <LockBox />

        <LocksContainer>
          <Balance />
          <SmartTable data={transactionsToTable} titles={['ID', 'Amount', 'Unlock in', 'Profit', 'Claim']} />
        </LocksContainer>
      </ConfigAndTable>
    </LocksContainer>
  );
}
